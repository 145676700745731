<template>
  <nav
    class="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3"
  >
    <div
      class="container px-4 mx-auto flex flex-wrap items-center justify-between"
    >
      <div
        class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
      ></div>
      <div
        class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
      >
        <router-link
          to="/"
          class="nav-link lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
          exact-active-class="nav-link active"
        >
          <img
            alt="利华电子"
            src="../assets/logo2.png"
            class="shadow-lg rounded-full max-w-full mx-auto mr-12"
            style="max-width: 60px"
          />
        </router-link>
        <button
          class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
          type="button"
          v-on:click="toggleNavbar()"
        >
          <i class="text-white fas fa-bars"></i>
        </button>
      </div>
      <div
        class="lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none"
        v-bind:class="{ hidden: !showMenu, block: showMenu }"
      >
        <ul class="flex flex-col lg:flex-row list-none mr-auto">
          <li class="flex items-center" @click="scrollView1()">
            <router-link
              to="/"
              class="nav-link lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              exact-active-class="nav-link active"
              ><i
                class="lg:text-gray-300 text-gray-500 fas fa-user-friends text-lg leading-lg mr-2"
              ></i>

              关于我们</router-link
            >
          </li>
          <li class="flex items-center">
            <router-link
              to="/product"
              class="nav-link lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              exact-active-class="nav-link active"
              ><i
                class="lg:text-gray-300 text-gray-500 fas fa-server text-lg leading-lg mr-2"
              ></i>

              产品中心</router-link
            >
          </li>
          <li class="flex items-center">
            <router-link
              to="/quality"
              class="nav-link lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              exact-active-class="nav-link active"
              ><i
                class="lg:text-gray-300 text-gray-500 fas fa-digital-tachograph text-lg leading-lg mr-2"
              ></i>
              质量保证</router-link
            >
          </li>
          <li class="flex items-center" @click="scrollView2()">
            <router-link
              to="/"
              class="nav-link lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              exact-active-class="nav-link active"
              ><i
                class="lg:text-gray-300 text-gray-500 far fa-address-card text-lg leading-lg mr-2"
              ></i>
              联系我们</router-link
            >
          </li>
        </ul>
        <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
          <!-- <li class="flex items-center">
            <a
              class="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              href="#pablo"
              ><i
                class="lg:text-gray-300 text-gray-500 fab fa-facebook text-lg leading-lg"
              ></i
              ><span class="lg:hidden inline-block ml-2">Share</span></a
            >
          </li>
          <li class="flex items-center">
            <a
              class="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              href="#pablo"
              ><i
                class="lg:text-gray-300 text-gray-500 fab fa-twitter text-lg leading-lg"
              ></i
              ><span class="lg:hidden inline-block ml-2">Tweet</span></a
            >
          </li>
          <li class="flex items-center">
            <a
              class="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              href="#pablo"
              ><i
                class="lg:text-gray-300 text-gray-500 fab fa-github text-lg leading-lg"
              ></i
              ><span class="lg:hidden inline-block ml-2">Star</span></a
            >
          </li> -->
          <li class="flex items-center">
            <button
              class="bg-white text-gray-800 active:bg-gray-100 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
              type="button"
              style="transition: all 0.15s ease 0s"
            >
              English
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    toggleNavbar: function () {
      this.showMenu = !this.showMenu;
    },
    scrollView1: function () {
      setTimeout(() => {
        console.log("111");
        document.getElementById("aboutus").scrollIntoView();
      }, 100);
    },
    scrollView2: function () {
      setTimeout(() => {
        document.getElementById("contactus").scrollIntoView();
      }, 100);
    },
  },
};
</script>
